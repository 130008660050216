import React, { useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';

import NextNavLink from '../../../util/NextNavLink';
import { Dropdown, Language } from '../Dropdown';

import { useDebounce, useClickOutside } from '../../../../util/hooks';
import { gtag } from '../../../../util/googleTagManager';
import { getAbsoluteUrl } from '../../../../lib/helpers';

/* An example Tailwind UI menu supporting sub-items as dropdowns */
function Navigation({
    menuItems,
    menuItemsCta,
    language,
    social,
    sticky,
    options,
    variant,
    layoutType,
    footer,
    headerTheme = '',
    hideMenu
}) {
    // console.log('Menu: ',menuItems);
    // console.log('Menu CTA: ',menuItemsCta);
    //For mobile menu
    const [showMenu, setShowMenu] = useState(false);
    const [CTAOpen, setCTAOpen] = useState(false);

    const closeMenu = () => {
        setShowMenu(false);
    };

    //for search results
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [activeMenu, setActiveMenu] = useState(null);
    const [showMegaMenu, setShowMegaMenu] = useState(false);

    const debouncedQuery = useDebounce(searchQuery, 500);

    const ref = useRef();
    const searchRef = useRef();

    useEffect(async () => {
        if (debouncedQuery && debouncedQuery.length > 2) {
            const resp = await fetch(
                '/api/search?' +
                new URLSearchParams({
                    q: searchQuery
                })
            );
            const json = await resp.json();

            if (json.response !== 'SUCCESS') {
                setSearchResults([]);
                setShowSearchResults(false);

                return;
            }

            setSearchResults(json.results);
            setShowSearchResults(true);
        } else {
            setSearchResults([]);
            setShowSearchResults(false);
        }
    }, [debouncedQuery]);

    function resetSearch() {
        setShowSearchResults(false);
        setSearchQuery('');
    }
    

    const toggleMenu = (index, display, isOpen) => {
        if(isOpen !== undefined)
            setShowMegaMenu(isOpen);

        const megaMenu = document.getElementById(`mega-menu-${index}`);
        hideMegaMenus(index);
        if (megaMenu) {
            if (display) {
                megaMenu.style.visibility = 'visible';
                megaMenu.style.opacity = '1';
            }
            // else{
            //     megaMenu.style.visibility = 'hidden';
            //     megaMenu.style.opacity = '0';
            // }
        }
    };

    const hideMegaMenus = (notHideIndex) => {
        const parent = document.querySelector('.mega-menu-nav');
        [...parent.children].map((element,i) => {
            if(element.dataset.index !== (notHideIndex ?? "").toString())
            {
                const li = document.getElementById(`mega-menu-${element.dataset.index}`);
                if(li){
                    li.style.visibility = 'hidden';
                    li.style.opacity = '0';
                }
            }
        });
    }

    const toggleMegaMenu = (index, display) => {
        const megaMenu = document.getElementById(`mega-menu-${index}`); 
        hideMegaMenus();

        if (megaMenu) {
            if (!display) {
                megaMenu.style.visibility = 'hidden';
                megaMenu.style.opacity = '0';
                setShowMegaMenu(false);
            }
        }
    };

    // useClickOutside(ref, () => setShowMenu(false));
    useClickOutside(searchRef, () => setShowSearchResults(false));

    let menuIconColorTheme =
        (sticky && !showMenu) || (!variant && !showMenu)
            ? 'bg-purple-midnight'
            : 'bg-white';
    let menuTextColor =
        (sticky && !showMenu) || (!variant && !showMenu)
            ? 'text-purple-midnight'
            : 'text-white';
            
    let megaMenuBgColor = variant ? 'bg-[#F5F6F6]' : 'bg-[#1E1A34]';
    let parentMenuBackColor = megaMenuBgColor === 'bg-[#1E1A34]' ? 'bg-[#2A1C41]' : 'bg-white';
    let submenuColor = megaMenuBgColor === 'bg-[#1E1A34]' ? 'text-white' : 'text-purple-midnight';
    let textColor = variant ? 'text-black' : 'text-white';

    // console.log('isdark', variant, 'megaMenuBgColor',megaMenuBgColor,'parentMenuBackColor',parentMenuBackColor,'submenuColor',submenuColor)
    // console.log('menuIconColorTheme', menuIconColorTheme)
    // console.log('sticky', sticky, 'showMenu', showMenu, 'variant', variant)

    return (
        <>
            <header className='fixed top-0 left-0 w-full px-6 py-4 z-[99] transition-all duration-400'
            // className={`fixed top-0 left-0 w-full px-6 py-4 z-[99] transition-all duration-400 ${sticky && !showMenu ? 'bg-white bg-opacity-100' : ''
            //     } ${!variant && !showMenu && !sticky ? '' : ''}  ${sticky && !showMenu ? 'shadow-nav' : ''
            //     } ${!showMenu && headerTheme}`}
            >
                <div className="flex items-center justify-between ">
                    <div
                    //     className={`fixed top-0 left-0 w-full px-6 py-4 z-[99] transition-all duration-400 ${sticky && !showMenu ? 'bg-white bg-opacity-100' : ''
                    // } ${!variant && !showMenu && !sticky ? '' : ''}  ${sticky && !showMenu ? 'shadow-nav' : ''
                    // } ${!showMenu && headerTheme}`}
                    >
                        <NextNavLink href="/">
                            <a
                                className="flex"
                                onClick={(e) => {
                                    setShowMenu(false);
                                }}
                            >
                                <span className="sr-only">Enfusion</span>
                                <img
                                    className="w-100% lg:max-w-[139px] max-w-[116px]"
                                    src={
                                        (sticky && !showMenu) ||
                                            (!variant && !showMenu)
                                            ? '/images/enfusion-logo-dark.svg'
                                            : '/images/enfusion-logo-white.svg'
                                    }
                                    alt="Enfusion"
                                />
                            </a>
                        </NextNavLink>
                    </div>

                    {!hideMenu && (
                        <div className="-mr-1 lg:hidden">
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowMenu(!showMenu);
                                }}
                                className={`flex items-center   lead opacity-80 hover:opacity-100 ${(sticky && !showMenu) ||
                                    (!variant && !showMenu)
                                    ? 'text-purple-midnight'
                                    : 'text-white'
                                    }`}
                            >
                                <span className="min-w-[42px]">
                                    {!showMenu ? `Menu` : 'Close'}
                                </span>
                                <span
                                    className={`${showMenu
                                        ? 'icon-expaded'
                                        : 'icon-collapse'
                                        } relative h-6 w-6 flex-col justify-between items-center flex ml-3`}
                                >
                                    <span
                                        className={`absolute inline-block w-1 h-1 rounded-10 ${menuIconColorTheme}`}
                                    />
                                    <span
                                        className={`relative top-0 inline-block w-1 h-1 rounded-10 ${menuIconColorTheme}`}
                                    />
                                    <span
                                        className={`inline-block w-1 h-1 rounded-10 ${menuIconColorTheme}`}
                                    />
                                    <span
                                        className={`relative bottom-0 inline-block w-1 h-1 rounded-10 ${menuIconColorTheme}`}
                                    />
                                    <span
                                        className={`absolute bottom-0 inline-block w-1 h-1 rounded-10 ${menuIconColorTheme}`}
                                    />
                                </span>
                            </a>
                        </div>
                    )}

                    {!hideMenu && (
                        <div
                            className={`hidden lg:flex-1 lg:flex lg:items-center lg:justify-end z-[100] `}
                           
                        >
                            <nav className={`relative pr-24 ${showMegaMenu ? `${parentMenuBackColor} p-4` : ''}`}
                             style={{
                                // backgroundColor: showMegaMenu ? 'white' : 'rgb(96, 65, 165)', // Purple background by default
                                // padding: showMegaMenu ? '20px' : '0',
                                 marginTop: showMegaMenu ? '-1%' : '0',
                                borderBottomLeftRadius: showMegaMenu ? '20px' : '0',
                                transition: 'all 0.4s ease-in-out',
                            }}
                            >
                                <ul className="flex items-center justify-center space-x-10 mega-menu-nav"
                                
                                
                                >
                                    {menuItemsCta.map((menuItem, index) => (
                                        <li
                                            key={index}
                                            data-index={index}
                                            className="group px-3 py-2"
                                            onMouseEnter={() => {
                                                toggleMenu(index, true,true);
                                            }}
                                            onMouseLeave={() => {
                                                toggleMenu(index, false);
                                            }}
                                        >
                                            <button
                                                className={`hover:opacity-50 hover:text-[#967EFF] cursor-default ${showMegaMenu ? textColor : menuTextColor}`}
                                                aria-expanded="false"
                                                aria-controls={`mega-menu-${index}`}
                                                style={{
                                                    // backgroundColor: showMegaMenu ? 'white' : 'rgb(96, 65, 165)', // Purple background by default
                                                    // padding: showMegaMenu ? '20px' : '0',
                                                    // color: showMegaMenu ? 'black' : '0',
                                                    borderBottomLeftRadius: showMegaMenu ? '20px' : '0',
                                                    transition: 'all 0.4s ease-in-out',
                                                }}
                                            >
                                                {menuItem.title}
                                            </button>
                                        </li>
                                    ))}


                                    {options.topCtaButton.title && (
                                        <li key={6} className="group px-3 py-2 z-[10]">
                                            <NextNavLink
                                                href={options?.topCtaButton?.url}
                                                target={options?.topCtaButton?.target}
                                            >
                                                <a
                                                    onClick={() => setShowMenu(false)}
                                                    className="cursor-pointer btn btn--primary"
                                                >
                                                    {options?.topCtaButton?.title}
                                                </a>
                                            </NextNavLink>
                                        </li>
                                    )}
                                    {options?.rightCtaButton?.title && (
                                        <li key={7} className="group px-3 py-2 z-[10]">
                                            <NextNavLink
                                                href={options?.rightCtaButton?.url}
                                                target={options?.rightCtaButton?.target}
                                            >
                                                <a
                                                    onClick={() => setShowMenu(false)}
                                                    className={`flex items-center lead opacity-80 hover:opacity-100 ${(sticky && !showMenu) || (!variant && !showMenu) ? 'text-purple-midnight' : showMegaMenu ? 'text-black' : menuTextColor}`}
                                                    >
                                                    {options?.rightCtaButton?.title}
                                                </a>
                                            </NextNavLink>
                                        </li>
                                    )}
                                    <li
                                        className="group px-3 py-2"
                                        onMouseEnter={() => {
                                            toggleMenu(index, true, true);
                                        }}
                                        onMouseLeave={() => {
                                            toggleMenu(index, false);
                                        }}
                                    >
                                        <button
                                            className={`hover:opacity-50 hover:text-[#967EFF] cursor-default ${showMegaMenu ? 'text-black' : menuTextColor}`}
                                            aria-expanded="false"
                                            style={{
                                                borderBottomLeftRadius: showMegaMenu ? '20px' : '0',
                                                transition: 'all 0.4s ease-in-out',
                                            }}
                                        >
                                            Sign in
                                        </button>
                                    </li>

                                </ul>
                            </nav>
                        </div>

                    )}
                </div>
                <div>
                    {menuItemsCta.map((menuItem, index) => (

                        menuItem.children.length > 0 && (
                            <div
                                id={`mega-menu-${index}`}
                                data-index={index}
                                className={`fixed top-0 right-0 pt-12 transition-opacity opacity-0 invisible group-hover:opacity-100  group-hover:visible ${megaMenuBgColor} duration-500 ease-in-out z-[40]`}
                                style={{
                                    minWidth: '1200px', // Ensure the width covers your content
                                    width: '100%',      // Make sure it stretches across the available width
                                    maxWidth: '1300px', // Optional: set a maximum width for better control
                                    transform: 'translateX(0)', // Adjust this if needed
                                    // background: '#F5F6F6',
                                    borderBottomLeftRadius: '20px;'
                                }}
                                onMouseLeave={() => {
                                    toggleMegaMenu(index, false);
                                }}
                            >

                                <div className="relative top-6 p-6 pb-12 rounded-xl shadow-xl w-full">
                                    <div
                                        className="w-10 h-10 transform rotate-45 absolute top-0 z-0 transition-transform duration-500 ease-in-out rounded-sm"
                                    >
                                    </div>
                                    <div className={`relative z-10  rounded-bl-xl`}>
                                        <div className={`grid grid-cols-${menuItem.children.length} gap-6`}>
                                            {menuItem.children.map((submenu, i) => {
                                                // Calculate the middle grid(s)
                                                const isMiddleGrid =
                                                    (menuItem.children.length % 2 === 1 && i === Math.floor(menuItem.children.length / 2)) ||
                                                    (menuItem.children.length % 2 === 0 && (i === Math.floor(menuItem.children.length / 2) || i === Math.floor(menuItem.children.length / 2) - 1));

                                                return (
                                                    <div
                                                        key={i}
                                                        className={`p-4 md:p-6 lg:p-6 ${isMiddleGrid ? 'border-l border-r' : ''}`} style={isMiddleGrid ? { borderLeft: '1px solid #3A2D7133', borderRight: '1px solid #3A2D7133' } : {}}
                                                    >
                                                        {!submenu.title.includes('@') && (
                                                            <p className={`uppercase tracking-wider text-[#967EFF] text-[14px]`} style={{ letterSpacing: '2.5px', fontWeight: 500 }}>
                                                                {submenu.title}
                                                            </p>
                                                        )}

                                                        <ul className="mt-3 text-[15px]">
                                                            {submenu.children.map((item, i) => (
                                                                <li key={i} className="my-10">
                                                                    {
                                                                        item.acf_menu_additional_fields?.displayAsBlock ?
                                                                            <div className="flex items-center text-white font-medium bg-purple min-h-32 min-w-80 justify-between p-5 rounded-lg" style={{borderRadius:'20px;'}}>
                                                                                <div className="text-white">
                                                                                    <div
                                                                                        dangerouslySetInnerHTML={{ __html: item.acf_menu_additional_fields?.summary }}
                                                                                    />
                                                                                </div>
                                                                                <div className="flex items-center space-x-4">
                                                                                    <div
                                                                                        className="text-18"
                                                                                    />
                                                                                    <NextNavLink
                                                                                        href={item?.url}
                                                                                        target={item?.target}
                                                                                    >
                                                                                        <a
                                                                                            onClick={(e) => setShowMenu(false)}
                                                                                            className="cursor-pointer btn btn--primary border border-white rounded-full px-4 py-2 text-15"
                                                                                            style={{ borderWidth: '1px', borderRadius: '20px' }}
                                                                                        >
                                                                                            {item?.title}
                                                                                        </a>
                                                                                    </NextNavLink>
                                                                                </div>
                                                                            </div>

                                                                            :
                                                                            <a href="#">
                                                                            <div className={`relative flex items-center ${submenuColor}`}>
                                                                                {item.acf_menu_additional_fields?.icon && (
                                                                                    <div
                                                                                        className="absolute left-0 flex items-center"
                                                                                        style={{
                                                                                            marginRight: '0.2rem', // Space between icon and title
                                                                                            top: '25%',
                                                                                            transform: 'translateY(-50%)' // Vertically center the icon with the title
                                                                                        }}
                                                                                        dangerouslySetInnerHTML={{ __html: item.acf_menu_additional_fields?.icon }}
                                                                                    />
                                                                                )}
                                                                                <div className="flex flex-col ml-[calc(2rem+1rem)]"> {/* Adjust left margin to account for icon */}
                                                                                    <div
                                                                                        style={{
                                                                                            letterSpacing: '0.2px',
                                                                                            fontWeight: 400,
                                                                                            fontSize: '15px',
                                                                                            lineHeight: '22px',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center', // Ensure text aligns with icon
                                                                                        }}
                                                                                    >
                                                                                        <span dangerouslySetInnerHTML={{ __html: item?.title }} />
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            letterSpacing: '0.25px',
                                                                                            fontWeight: 400,
                                                                                            fontSize: '12px',
                                                                                            lineHeight: '18px',
                                                                                            marginTop: '0.5em' // Adjust margin for summary
                                                                                        }}
                                                                                        dangerouslySetInnerHTML={{ __html: item.acf_menu_additional_fields?.summary }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        





                                                                    }

                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                    </div>
                                </div>
                            </div>

                        )
                    ))}
                </div>
            </header>
            <Transition
                show={showMenu}
                className="relative z-[11]"
                enter="duration-200 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="fixed top-0 left-0 z-10 flex flex-col justify-between w-screen max-h-screen min-h-screen overflow-auto bg-cover bg-gradient-10">
                    <div className="container">
                        <div className="fixed top-0 left-0 flex items-center justify-between w-full p-6 pt-20 lg:hidden bg-gradient-to-b from-black via-black to-black-0 z-[2]"></div>
                        <div className="grid flex-row flex-wrap w-full gap-10 pb-32 mb-10 md:gap-8 lg:justify-center sm:grid-cols-2 lg:grid-cols-4 lg:pt-40 pt-28 lg:pb-0">
                            <div className="flex items-center mb-1 space-x-4 sm:col-span-2 lg:hidden">
                                <NextNavLink href="/clients">
                                    <a
                                        onClick={() => setShowMenu(false)}
                                        className="flex items-center font-medium text-white lead icon-circle"
                                    >
                                        Clients
                                        <span className="relative h-4 ml-4">
                                            <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                                            <svg
                                                width="32"
                                                height="32"
                                                className="absolute -right-3 -top-2 icon-circle__circle"
                                            >
                                                <circle
                                                    cx="16"
                                                    cy="16"
                                                    r="15"
                                                    className="icon-circle__base"
                                                ></circle>
                                                <circle
                                                    cx="16"
                                                    cy="16"
                                                    r="15"
                                                    className="icon-circle__fill"
                                                ></circle>
                                            </svg>
                                        </span>
                                    </a>
                                </NextNavLink>
                                {footer.footerLink2 && (
                                    <div className="lg:pr-5">
                                        <a
                                            href={getAbsoluteUrl(
                                                footer.footerLink2?.url
                                            )}
                                            target={footer.footerLink2?.target}
                                            className="flex items-center font-medium text-white lead icon-circle"
                                        >
                                            {footer.footerLink2?.title}
                                            <span className="relative h-4 ml-3">
                                                <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                                                <svg
                                                    width="32"
                                                    height="32"
                                                    className="absolute -right-3 -top-2 icon-circle__circle"
                                                >
                                                    <circle
                                                        cx="16"
                                                        cy="16"
                                                        r="15"
                                                        className="icon-circle__base"
                                                    ></circle>
                                                    <circle
                                                        cx="16"
                                                        cy="16"
                                                        r="15"
                                                        className="icon-circle__fill"
                                                    ></circle>
                                                </svg>
                                            </span>
                                        </a>
                                    </div>
                                )}
                            </div>
                            {menuItems.map((menuItem, index) => (
                                <div
                                    className={`flex flex-col lg:flex-1  w-full space-y-11 ${index === 3
                                        ? 'lg:border-l lg:border-opacity-30 lg:border-white lg:pl-24'
                                        : ''
                                        }`}
                                    key={index}
                                >
                                    {menuItem.children.map(
                                        (childMenuItem, index) => (
                                            <div key={index} className="">
                                                <p className="mb-3 font-medium text-white label">
                                                    {childMenuItem.title}
                                                </p>
                                                <ul className="flex flex-col space-y-3 lead">
                                                    {childMenuItem.children.map(
                                                        (item, i) => (
                                                            <li key={i}>
                                                                <NextNavLink
                                                                    href={
                                                                        item.path ||
                                                                        item.url
                                                                    }
                                                                    target={
                                                                        item.target
                                                                    }
                                                                >
                                                                    <a
                                                                        onClick={() =>
                                                                            setShowMenu(
                                                                                false
                                                                            )
                                                                        }
                                                                        className="text-white opacity-70 hover:opacity-100"
                                                                    >
                                                                        {
                                                                            item.title
                                                                        }
                                                                    </a>
                                                                </NextNavLink>
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        )
                                    )}

                                    {index === 3 && (
                                        <div className="flex flex-row items-center pt-2 space-x-4 text-20 leading-20">
                                            {social &&
                                                social.map((item, index) => (
                                                    <a
                                                        key={index}
                                                        href={getAbsoluteUrl(
                                                            item.link
                                                        )}
                                                        className="text-white opacity-70 hover:opacity-100"
                                                        target="_blank"
                                                    >
                                                        <i
                                                            className={`en-${item.social}`}
                                                        ></i>
                                                    </a>
                                                ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    {options.ctaHeading && (
                        <Banner
                            heading={options.ctaHeading}
                            cta={options.ctaBannerLink}
                        />
                    )}

                    <div className="fixed bottom-0 left-0 flex items-center justify-between w-full p-6 pt-24 lg:hidden bg-gradient-to-t from-black to-black-0 z-[99]">
                        {options?.topCtaButton?.title && (
                            <NextNavLink
                                href={options?.topCtaButton?.url}
                                target={options?.topCtaButton?.target}
                            >
                                <a
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     setCTAOpen(true);
                                    // }}
                                    onClick={(e) => {
                                        setShowMenu(false);
                                    }}
                                    className="cursor-pointer btn btn--primary"
                                >
                                    {options?.topCtaButton?.title}
                                </a>
                            </NextNavLink>
                        )}
                        {options?.rightCtaButton?.title && (
                            <NextNavLink
                                href={options?.rightCtaButton?.url}
                                target={options?.rightCtaButton?.target}
                            >
                                <a
                                    onClick={(e) => {
                                        setShowMenu(false);
                                    }}
                                    className={`flex items-center lead opacity-80 hover:opacity-100 ${(sticky && !showMenu) ||
                                        (!variant && !showMenu)
                                        ? 'text-purple-midnight'
                                        : 'text-white'
                                        }`}
                                >
                                    {options?.rightCtaButton?.title}
                                </a>
                            </NextNavLink>
                        )}
                        {language.map((menuItem, index) => (
                            <Language
                                key={index}
                                menuItem={menuItem}
                                sticky={sticky && !showMenu}
                                bottom={true}
                            />
                        ))}
                    </div>
                </div>
            </Transition>
            <Transition
                show={CTAOpen}
                className="fixed top-0 left-0 z-[999] w-screen max-h-screen min-h-screen"
                enter="duration-200 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="w-screen max-h-screen min-h-screen bg-white bg-cover">
                    <div className="fixed right-6 top-7">
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setCTAOpen(!CTAOpen);
                            }}
                            className={`flex items-center lead opacity-80 hover:opacity-100 xl:text-purple-midnight text-white cursor-pointer`}
                        >
                            <span className="min-w-[42px]">Close</span>
                            <span
                                className={`icon-expaded  relative h-6 w-6 flex-col justify-between items-center flex ml-3`}
                            >
                                <span
                                    className={`absolute inline-block w-1 h-1 rounded-10 xl:bg-purple-midnight bg-white`}
                                />
                                <span
                                    className={`relative top-0 inline-block w-1 h-1 rounded-10 xl:bg-purple-midnight bg-white`}
                                />
                                <span
                                    className={`inline-block w-1 h-1 rounded-10 xl:bg-purple-midnight bg-white`}
                                />
                                <span
                                    className={`relative bottom-0 inline-block w-1 h-1 rounded-10 xl:bg-purple-midnight bg-white`}
                                />
                                <span
                                    className={`absolute bottom-0 inline-block w-1 h-1 rounded-10 xl:bg-purple-midnight bg-white`}
                                />
                            </span>
                        </a>
                    </div>
                    <iframe
                        loading="lazy"
                        sandbox="allow-downloads allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation-by-user-activation allow-same-origin"
                        src={options?.topCtaButton?.url}
                        data-src=""
                        width="100%"
                        height="100%"
                        type="text/html"
                        frameborder="0"
                        className="w-screen max-h-screen min-h-screen"
                        allowtransparency="true"
                    />
                </div>
            </Transition>
        </>
    );
}

// Desktop menu Bottom Baner
// Content can be changed from Option page
function Banner({ heading, cta }) {
    return (
        <div className="hidden py-6 bg-center bg-cover bg-gradient-3 lg:block">
            <div className="container flex flex-row items-center justify-between">
                <h5 className="text-white">{heading}</h5>
                {cta && (
                    <a
                        target={cta.target}
                        href={getAbsoluteUrl(cta.url)}
                        className="flex items-center font-medium text-white lead icon-circle"
                    >
                        {cta.title}
                        <span className="relative flex items-center h-4 ml-4">
                            <i className="inline-block en-long-arrow text-32 leading-18 relative z-[1]"></i>

                            <svg
                                width="32"
                                height="32"
                                className="absolute -right-3 -top-2 icon-circle__circle"
                            >
                                <circle
                                    cx="16"
                                    cy="16"
                                    r="15"
                                    className="icon-circle__base"
                                ></circle>
                                <circle
                                    cx="16"
                                    cy="16"
                                    r="15"
                                    className="icon-circle__fill"
                                ></circle>
                            </svg>
                        </span>
                    </a>
                )}
            </div>
        </div>
    );
}
export default Navigation;
